import React from 'react'
import PropTypes from 'prop-types'
import Container from '@objects/container'
import Stage from '@components/stage'
import Copy from '@objects/copy'
import { graphql } from 'gatsby'
import useStaticModule from '@hooks/useStaticModule'
import Card from '@objects/card'

import EventMap from '@components/eventMap'

function BuergerdialogeVorOrt(props) {
  const { data, pageContext } = props

  const { getStaticFieldValue } = useStaticModule(pageContext.modules)
  const copy = getStaticFieldValue(
    'dialogveranstaltungen.copy',
    'dialogveranstaltungen.copy.text'
  )
  const headline = getStaticFieldValue(
    'dialogveranstaltungen.stage',
    'dialogveranstaltungen.stage.headline'
  )

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={headline}
        ariaLabel={headline}
      />
      <Container width="sm" ariaLabel="arialabel.citizensdialog">
        <Copy html={copy} component="div" />
      </Container>

      <EventMap headline={headline} allevents={data.allContentfulEvent.nodes} />
    </>
  )
}

BuergerdialogeVorOrt.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default BuergerdialogeVorOrt

export const pageQuery = graphql`
  query EventPageQuery($locale: String!) {
    allContentfulEvent(filter: { node_locale: { eq: $locale } }) {
      nodes {
        summary {
          json
        }
        title
        location {
          lat
          lon
        }
        attending
        format
        eventDate
        id
      }
    }

    contactText: contentfulStaticField(key: { eq: "contact.teaser.text" }) {
      value {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`
